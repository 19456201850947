/* ==========================================================================
Date and Time controls
========================================================================== */

"use strict";

import $ from "jquery"

//Datepicker (date dropper)
export function initDatepicker() {
  if ($("#is-datepicker").length) {
    $("#is-datepicker").dateDropper();
  }
}

//Timepicker (time dropper)
export function initTimepicker() {
  if ($("#is-timepicker").length) {
    $("#is-timepicker").timeDropper({
      primaryColor: "#4FC1EA",
      borderColor: "#4FC1EA",
      backgroundColor: "#FFF",
      init_animation: "fadeIn",
    });
  }
}

//Datepicker (fengyuanchen)
export function initDatepickerAlt() {
  $('[data-toggle="datepicker"]').datepicker();
}
