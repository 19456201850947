/* ==========================================================================
Counters
========================================================================== */

"use strict";
import $ from "jquery"

export function initCounters() {
  if ($(".counter").length) {
    $(".counter").counterUp({
      delay: 10,
      time: 1000,
    });
  }
}
