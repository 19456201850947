export default function TermsOfServices() {

  return(
    <>
      <h3 className="is-size-7 danger-text">Last updated: 10/1/2019</h3>
      <br />
      <p>Dear beloved clients it`s a pleasure to provide you with our services, and to contact us you should do the following.</p>
      <br />
      <p>These Terms of Use and all policies and additional terms (if applicable) posted on the Site set out the terms on which we offer you access to and use of our Site, services and applications including our mobile application (collectively, the “Services”). You can find all of our policies and additional terms here: www.grafixshop.com (“Legal Documents”). These Legal Documents are incorporated by reference into these Terms of Use.</p>
      <br />
      <p>By accessing, registering and/or continuing to use or access our Services, you are agreeing to be bound by these Terms of Use and the Legal Documents with immediate effect. These Terms of Use and the Legal Documents are subject to change by us at any time. Your continued use of the Site following any such change constitutes your agreement to these Terms of Use and Legal Documents as so modified.</p>
      <br />
      <p>References in these Terms of Use to “you” (or similar) are references to you as an individual or legal entity as the case may be.</p>
      <h3 className="title is-3 mt-40">About Our Site</h3>
      <p>This site is a platform for creating graphic designs, clips and visual effects that allow users from commercial institutions, companies, governments and individuals, Request services according to the specified conditions and the advertised prices for the services.</p>
      <h3 className="title is-3 mt-40">Your Obligations</h3>
      <p>When using or accessing the Services, you agree that you:</p>
      <br />
      <ul style={{listStyle: 'disc'}}>
        <li>Are responsible for maintaining the confidentiality of, and restricting access to and use of your account and password, and accept responsibility for all activities that occur under your account and password.</li>
        <li>Agree to immediately notify us of any unauthorised use of your password or account or any other breach of security.</li>
        <li>Will provide true, accurate, current and complete information about yourself and your use of the Services as required by us.</li>
        <li>Will not disclose to any third party (except as required or requested by us) a user’s information provided to you.</li>
      </ul>
      <h3 className="title is-3 mt-40">Intellectual Property Rights</h3>
      <ul style={{listStyle: 'disc'}}>
        <li>
          Except for the rights expressly granted under these Terms of Use:
          <ul>
            <li>All content included on the Site, including but not limited to text, graphics, logos, images, audio clips, digital downloads and software is our property or the property of our licensors. We (or our licensors, as the case may be) retain all right, title and interest in and to the Site and the Services, including, without limitation, all Intellectual Property Rights therein</li>
            <li>All rights, title and interest in and to any information, materials or other content that you provide in connection with your use of the Services, including all Intellectual Property Rights therein, will become our property.</li>
          </ul>
        </li>
        <li>You agree that you have no right to use any of our trademarks without our prior written consent.</li>
      </ul>
      <h3 className="title is-3 mt-40">General Terms of Use</h3>
      <ul>
        <li>
          you should send the client`s trade mark or it`s visual effects which shows the colors in use and the languages font, to our Company “Google drive” folder, this file should also include :
          <ul>
            <li>the Arabic & English font files</li>
            <li>Color codes</li>
            <li>the client slogan/trade mark High quality is preferred</li>
          </ul>
        </li>
        <li>In the begging of a month ,the client in charge should contact us to manage the colors, the design and the general overview for the upcoming month.</li>
        <p>And we shall send some samples through google drive, and when we receive a confirmation, we start working on the designs according to the samples we sent before .cause we care about the client`s company identity and consider the client`s vision</p>
        <li>The order should be written in details .And any side notes should be written in its specific place. In order to decrease</li>
        <p>the modification and avoid any mistakes as possible.</p>
        <li>upload Your photos or videos to the google drive/we transfer in order to avoid any work conflict.</li>
        <li>receiving tasks better  be at the beginning of the week (Saturday) , and delivering them will be at the end of the week (Thursday).</li>
      </ul>
      <h3 className="title is-3 mt-40">Animation Service Terms of Use</h3>
      <ul>
        <li>short videos that don`t exceed 15 seconds including 1 scene or 2 as max</li>
        <li>the project only goes with a confirmation of the client's company  marketing department for the designs.</li>
        <li>the time-plan for any animation work can be obtained through us.</li>
        <li>any drastic modification after the design and animations are done is consider a new task
          For example :
          If a client asked for a design for hair product, and we did it as exactly he asked, if the client asked to modify the design
          for a skin product, that`s considered a new task with new time-plan and cost.
          but the reasonable modifications is totally acceptable and we do our best to make the best.
          For example:
          <ul>
            <li>font size and the text order</li>
            <li>the picture in use</li>
            <li>colors changes</li>
          </ul>
          All such modifications are totally free.
        </li>
      </ul>
      <h3 className="title is-3 mt-40">And For The Long Term Contracts</h3>
      <ul>
        <li>When canceling the monthly packages, the service will be suspended and the monthly withdrawal will be suspended
          But the month will be calculated at the higher cost</li>
        <li>A month starts and ends according to the Gregorian calendar </li>
        <li>We receive the project cost as a payment system , and that still continues even in case that the tasks needed takes less than the agreed .</li>
        <li>In case the client ordered more tasks  , that adds new cost on the total . also we inform the client with that before we start the project.</li>
        <li>The package cannot be collected every month independently, and in the event that the tasks are less than required, the remainder is not carried over to the next month</li>
        <li>in case of contract for 3 or 6 months or more , 100% of the total contract value is paid upfront and It is non-cancellable or refundable</li>
        <li>We have the right to modify the packages in proportion to the changing conditions and in the event that the customer or the company does not accept the payment , We reduce the volume of services to a minimum.</li>
      </ul>
      <h3 className="title is-3 mt-40">Termination of Contract</h3>
      <ul>
        <li>Upon cancellation by the customer, the amount is not refundable, and this applies to both long-term and government contracts</li>
        <li>in case (Grafixshop) terminates the contract , the month value is applied as the agreement , and rest of the value is refunded</li>
        <li>we have the right  to show all of the projects on our  social media channels . also using our logo on all of projects. And encase client refuses that, client should pay for that</li>
      </ul>
    </>
  )
}
