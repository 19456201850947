// noinspection ES6CheckImport
import {collection} from "firebase/firestore";
import {useFirestore} from "reactfire";
import {useForm} from "react-hook-form";
import Inquiry from "models/Inquiry";
import {create} from "API.js";
import drawing from "assets/img/graphics/drawings/drawing-6-blue.svg"

export default function Footer() {

  const firestore = useFirestore();
  const { register, handleSubmit, reset } = useForm();

  // Methods
  async function onSubmit(data) {
    try {
      const inquiry = new Inquiry(data)
      inquiry.validate()
      await create(collection(firestore, "inquiries"), inquiry.data)
      await reset()
    } catch (err) {
      await swal('Warning', err.message, 'warning')
    }
  }

  return(
    <div id={'contact-us'} className="section is-medium section-feature-grey">
      <div className="container">

        <div className="section-title-wrapper has-text-centered">

          <div className="special-divider">
            <span/>
            <span/>
          </div>
          <h2 className="title is-2">Drop us a Line.</h2>
          <h4 className="subtitle is-4">Ask us a question, or just say Hello.</h4>
        </div>
        <div className="columns">
          <div className="column is-6">

            <div className="contact-form">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
                <div className="columns is-multiline">
                  <div className="column is-6">
                    <div className="control">
                      <label>First name *</label>
                      <input className="input is-medium" type="text" autoComplete="chrome-off"
                             {...register('firstName')}/>
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="control">
                      <label>Last name *</label>
                      <input className="input is-medium" type="text" autoComplete="chrome-off"
                             {...register('lastName')}/>
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="control">
                      <label>Email *</label>
                      <input className="input is-medium" type="email" autoComplete="chrome-off"
                             {...register('email')} />
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="control">
                      <label>Company</label>
                      <input className="input is-medium" type="text" autoComplete="chrome-off"
                             {...register('company')}/>
                    </div>
                  </div>
                  <div className="column is-12">
                    <div className="control">
                      <label>Message *</label>
                      <textarea className="textarea" rows="4" autoComplete="chrome-off"
                                {...register('message')}/>
                    </div>
                  </div>
                </div>
                <div className="submit-wrapper">
                  <button className="button button-cta is-bold btn-align primary-btn raised" type="submit">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="column is-5 is-offset-1">

            <div className="contact-toggler">
              <ul className="tabbed-links">
                <li className="is-active" data-contact="contact-email">Email</li>
                {/*<li data-contact="contact-location">Location</li>*/}
                {/*<li data-contact="contact-phone">Phone</li>*/}
              </ul>
              <div className="contact-blocks">
                <div id="contact-email" className="contact-block animated preFadeInUp fadeInUp">
                  <div className="contact-icon">
                    <i className="fa fa-envelope"/>
                  </div>
                  <div className="contact-info">
                    <span>Contact us by email</span>
                    <span><a href="mailto: info@grafixshop.com">info@grafixshop.com</a></span>
                  </div>
                </div>
                {/*<div id="contact-location" className="contact-block animated preFadeInUp fadeInUp is-hidden">*/}
                {/*  <div className="contact-icon">*/}
                {/*    <i className="fa fa-map"/>*/}
                {/*  </div>*/}
                {/*  <div className="contact-info">*/}
                {/*    <span>Visit us @ our Office</span>*/}
                {/*    <span>Frankfurt, Germany</span>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div id="contact-phone" className="contact-block animated preFadeInUp fadeInUp is-hidden">*/}
                {/*  <div className="contact-icon">*/}
                {/*    <i className="fa fa-phone"/>*/}
                {/*  </div>*/}
                {/*  <div className="contact-info">*/}
                {/*    <span>Call our Support team</span>*/}
                {/*    <span>+49 151 41948166</span>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>

            <img src={drawing} data-extension=".svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
