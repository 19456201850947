import React from "react";
import ReactDOM from "react-dom";
import { FirebaseAppProvider } from 'reactfire';
import ReactNotification from 'react-notifications-component'

import App from "App.jsx";

import {firebaseConfig} from "firebase.js";
import 'react-notifications-component/dist/theme.css'

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <ReactNotification />
    <App />
  </FirebaseAppProvider>,
  document.getElementById("root")
);
