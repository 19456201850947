/* ==========================================================================
Popups
========================================================================== */

"use strict";

import $ from "jquery"

//Popovers
export function initPopovers() {
  if ($('[data-toggle="popover"]').length) {
    $('[data-toggle="popover"]').ggpopover();
  }
}

//Tooltips
export function initTooltips() {
  if ($('[data-toggle="tooltip"]').length) {
    $('[data-toggle="tooltip"]').ggtooltip();
  }
}
