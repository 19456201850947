export default function StarFall() {
  return(
    <div className="starfall">
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
      <div className="falling-star"/>
    </div>
  )
}
