import Loader from 'components/Loader';
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import { FirestoreProvider } from "reactfire";

import MainLayout from 'layouts/MainLayout';

// Contexts
import { NotificationProvider } from "contexts/NotificationsContext";

import {firestore} from "firebase.js"

import './custom.scss'


export default function App() {


  return(
    <FirestoreProvider sdk={firestore}>
      <NotificationProvider>
        <Router>
          <Loader />
          <MainLayout />
        </Router>
      </NotificationProvider>
    </FirestoreProvider>
  )
}
