import {Link, NavLink, Redirect, Route, Switch, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import routes from "routes.js";
import useScript from "hooks/useScript";
import Footer from "components/Footer";

import("assets/scss/blue.scss")
import("assets/vendor/css/icons.min.css")

import {initLayout} from "assets/js/main.js";
import logo from "assets/img/logos/logo/logo.png";
import {Link as ScrollLink} from "react-scroll";

export default function MainLayout() {

  let location = useLocation();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => (<Route key={key} exact path={prop.path} component={prop.component}/>) );
  };


  useEffect(() => {
    setTimeout(() => initLayout(), 1000)
  }, [location])

  useScript("https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.js")

  return (
    <div className="is-theme-blue">
      <div className="navbar-placeholder" style={{height: '64.3906px'}}>
        <nav className="navbar navbar-wrapper navbar-default navbar-fade is-transparent">
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item" to={{pathname: '/', hash: '#sign-up-form'}}>
                <img className="switcher-logo" src={logo} alt="" />
              </Link>
              {/* TODO */}
              {/*<a id="navigation-trigger" className="navbar-item hamburger-btn" href={"#"} style={{opacity: 1}}>*/}
              {/*  <span className="menu-toggle">*/}
              {/*    <span className="icon-box-toggle">*/}
              {/*      <span className="rotate">*/}
              {/*        <i className="icon-line-top"/>*/}
              {/*        <i className="icon-line-center"/>*/}
              {/*        <i className="icon-line-bottom"/>*/}
              {/*      </span>*/}
              {/*    </span>*/}
              {/*  </span>*/}
              {/*</a>*/}

              <div className="custom-burger" data-target="">
                <a id="" className="responsive-btn" href={"#"}>
                  <span className="menu-toggle">
                    <span className="icon-box-toggle">
                      <span className="rotate">
                        <i className="icon-line-top"/>
                        <i className="icon-line-center"/>
                        <i className="icon-line-bottom"/>
                      </span>
                    </span>
                  </span>
                </a>
              </div>
            </div>

            <div className="navbar-menu">
              <div className="navbar-start">
                <Link className="navbar-item is-slide" to={{
                  pathname: '/',
                  hash: "#about"
                }}>
                  About
                </Link>
                <Link className="navbar-item is-slide" to={{pathname: '/', hash: '#features'}}>
                  Features
                </Link>
                <NavLink className="navbar-item is-slide" exact to={{pathname: '/pricing', hash: '#pricing'}}
                         activeClassName={'is-active'}>
                  Pricing
                </NavLink>
                <ScrollLink to="contact-us" smooth={true} className="navbar-item is-slide">
                  Contact Us
                </ScrollLink>
                {/* TODO */}
                {/*<Link to={"client/index"} className="navbar-item is-slide">*/}
                {/*  Dashboard*/}
                {/*</Link>*/}
              </div>

              <div className="navbar-end">
                <div className="navbar-item">
                  <a id="#signup-btn" href={"#"}
                     className="button button-cta btn-outlined is-bold btn-align primary-btn rounded raised">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="infraloader"/>
      {/* Routes */}
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to={`/`} />
      </Switch>
      <Footer />
    </div>
  )
}
