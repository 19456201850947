import Model from "models/Model.js";
import Joi from "joi";

export default class Inquiry extends Model {

  data = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    message: "",
  }

  // noinspection JSCheckFunctionSignatures
  schema = Joi.object({
    firstName: Joi.string().label('First Name').min(3),
    lastName: Joi.string().label('Last Name').min(3),
    email: Joi.string().label('Email').email({ tlds: {allow: false} }),
    company: Joi.string().allow('').label('Company'),
    message: Joi.string().label('Message').min(3),
  })

  constructor(data) {
    super();
    this.data = {...this.data, ...data};
    return this;
  }
}
