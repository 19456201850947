export default function CookiesPolicy() {

  return(
    <>
      <h3 className="is-size-7 danger-text">Effective as of: Dec 1, 2019</h3>
      <br />
      <p>Hi, we’re grafixshop . You’re probably here because you’re interested to know more about how we use cookies, web beacons and other similar technologies. As a part of our commitment to upholding a high standard of transparency in our Privacy Policy, we’ve created this guide to explain the tracking technologies we use on our sites.</p>
      <h3 className="title is-3 mt-40">What are cookies, web beacons, and similar technologies?</h3>
      <h3 className="is-size-4">Typically, there are two types of tracking technologies we might use on our sites:</h3>
      <br />
      <ul style={{listStyle: 'disc'}}>
        <li>Cookies: these aren’t the type you eat they’re actually a small data file sent from a server to your web browser or mobile device that is stored on your browser cache or mobile device. There are ways you can control your cookies preferences and set whether you want to accept or reject cookies (see what your options are below).</li>
        <li>Clear gifs, web beacons, web bugs: These are tiny graphics with a unique identifier similar in function to cookies, and are used to track the movements of web users between pages and websites. Unlike cookies, which are cached on a user’s computer, clear gifs, web beacons and web bugs are embedded invisibly on web pages and are about the size of a single pixel.</li>
      </ul>
      <h3 className="title is-3 mt-40">What do we do with these technologies?</h3>
      <h3 className="is-size-4">We use these tracking technologies for a few general purposes like:</h3>
      <br />
      <ul style={{listStyle: 'disc'}}>
        <li>To allow our sites to function correctly.</li>
        <li>To understand how our sites are functioning and to inform any improvements in performance and our services.</li>
        <li>To enhance your experience on our sites and provide functionality that means for example you don’t have to re-enter your details each time you engage with our sites or to record what purchases you’ve made on our sites.</li>
        <li>To gather relevant data that helps us deliver marketing and advertising content relevant to your interests.</li>
      </ul>
      <br />
      <p>We might sometimes partner with third-party services who may use various tracking technologies to provide certain services or features on our sites, including targeted online marketing or relevant on-site messaging. These third-party services use cookies to anonymously collect data and allow them to recognize your computer or mobile device each time you visit any of our sites. No personally identifiable information is collected by these cookies. The anonymous data they collect is kept separate from the personal information about you as a user that we collect.</p>
      <h3 className="title is-3 mt-40">Your options when it comes cookies, web beacons and similar technologies</h3>
      <p>You can change your web browser’s settings to reflect your cookie preferences. Use these links to find out more information about cookie settings for these common browsers:</p>
      <br />
      <ul style={{listStyle: 'disc'}}>
        <li><a href="http://windows.microsoft.com/en-US/windows7/Block-enable-or-allow-cookies">Internet Explorer</a></li>
        <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Firefox</a></li>
        <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Chrome</a></li>
        <li><a href="https://support.apple.com/en-us/HT201265">Safari</a></li>
      </ul>
      <br />
      <p>Just keep in mind that if you disable cookies, web beacons and similar technologies there might be some functionality that will not work or not operate correctly on our sites.</p>
      <p>If you have any questions about our privacy practices, please contact us <a href="mailto: info@grafixshop.com">info@grafixshop.com</a></p>
    </>
  )
}
