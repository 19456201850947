import Joi from 'joi';

/**
 * Base model used to validate and enforce attributes
 */
export default class Model {
  // The data of the model
  data = {
    id: '',
  };

  // validation rule
  schema = Joi.object({
    id: Joi.string(),
  });

  /**
   * Validates the model
   */
  validate() {
    const {error} = this.schema.validate(this.data);
    if (error) {
      // noinspection JSCheckFunctionSignatures
      throw new Error(error);
    }
  }
}
