import Model from 'models/Model.js';
import Joi from 'joi';

/**
 * Sign Up requests sent to the admin
 */
export default class SignUpRequest extends Model {
  /**
   * Returns the valid status for the signup request
   */
  static get STATUS() {
    return {
      PENDING: 'Pending',
      ACCEPTED: 'Accepted',
      REJECTED: 'Rejected',
    };
  }

  data = {
    displayName: '',
    email: '',
    password: '',
    status: SignUpRequest.STATUS.PENDING,
    termsAgreed: false,
  };

  // noinspection JSCheckFunctionSignatures
  schema = Joi.object({
    displayName: Joi.string().label('Full Name').min(3),
    email: Joi.string().label('Email Address').email({tlds: {allow: false}}),
    password: Joi.string().label('Password').min(8).max(30),
    passwordConfirmation: Joi.any().equal(Joi.ref('password')).required().label('Repeated password')
        .messages({'any.only': '{{#label}} must match'}),
    status: Joi.string().allow(SignUpRequest.STATUS.PENDING).only(),
    termsAgreed: Joi.boolean().allow(true).only()
        .messages({'any.only': 'You must agree on Grafix Shop terms and conditions'}),
  });

  constructor(data) {
    super();
    this.data = {...this.data, ...data};
    return this;
  }
}
