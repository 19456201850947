import {Link} from 'react-router-dom';
import {Link as ScrollLink} from "react-scroll";
import {useFirestore} from "reactfire";
import {useForm} from "react-hook-form";
// noinspection ES6CheckImport
import {collection} from "firebase/firestore";
import {create} from "API.js";
import SignUpRequest from "models/SignUpRequest";
import StarFall from "components/StarFall";
import swal from 'sweetalert';

// Assets
// TODO
// import squareWhite from "assets/img/logos/square-white.svg"
// import cssNinja from "assets/img/logos/cssninja.svg"
import creativeTeam from "assets/img/graphics/icons/launch-blue.svg"
import easyToUse from "assets/img/graphics/icons/send-blue.svg"
import manageTasks from "assets/img/graphics/icons/manage-tasks.svg"
import workFast from "assets/img/graphics/icons/chrono-blue.svg"
import FeatherIcon from "feather-icons-react";


export default function Home() {

  return(
    <>
      <div id="sign-up-form" className="hero product-hero is-app-grey is-relative" data-page-theme="blue">
        <StarFall />
        <div id="main-hero" className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-5 is-offset-1 signup-column has-text-left">
                <div className="update-pill">
                  <span className="inner-tag">New Update</span>
                  <span className="inner-text">Check out the newest features</span>
                </div>

                <h1 className="title main-title text-bold dark-text is-2">
                  Grafix Shop
                </h1>
                <h2 className="subtitle is-5 no-margin-bottom body-color">
                  The Featured Design Needs Artists And Not Just Employees. This Is Our Secret Of Success
                </h2>

                <div className="buttons mt-20">
                  <a href={"#"} className="button button-cta btn-align is-bold primary-btn">
                    Get Started
                  </a>
                  <a href={"#"} className="button button-cta btn-align is-bold light-btn">
                    Get a Quote
                  </a>
                </div>
              </div>
              <div className="column is-5 is-offset-1">
                <SignupForm />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About */}
      <section id="about" className="section section-feature-grey is-medium is-skewed-sm">
        <div className="container is-reverse-skewed-sm">

          <div className="section-title-wrapper has-text-centered">
            {/*TODO: <div className="bg-number">4</div>*/}
            <h2 className="section-title-landing">About Grafix App</h2>
            <h4>Grafix Is Dynamic, High-Performance And Easy To Use App. Provides You With A High Quality Of Work.
              Graphic Designing | Animation | Videography & Photography | Video Editing | Consultation</h4>
          </div>

          <div className="content-wrapper floating-circles">
            <div className="columns is-flex-wrap-wrap is-justify-content-space-around">
              <div className="column is-4">
                <div className="floating-circle levitate is-icon-reveal">
                  <img src={creativeTeam} alt="..." />
                </div>
                <div className="has-text-centered floating-text">
                  <span className="clean-text">Creative Team</span>
                </div>
              </div>
              <div className="column is-4">
                <div className="floating-circle levitate delay-2 is-icon-reveal">
                  <img src={easyToUse} alt="..." />
                </div>
                <div className="has-text-centered floating-text">
                  <span className="clean-text">Easy To Use</span>
                </div>
              </div>
              <div className="column is-4">
                <div className="floating-circle levitate delay-4 is-icon-reveal" style={{paddingTop: '15px'}}>
                  <img src={manageTasks}
                       data-base-url="assets/img/graphics/icons/credit-card" data-extension=".svg" alt="" />
                </div>
                <div className="has-text-centered floating-text">
                  <span className="clean-text">Manage Tasks</span>
                </div>
              </div>
              <div className="column is-4">
                <div className="floating-circle levitate delay-4 is-icon-reveal">
                  <img src={workFast} alt="..." />
                </div>
                <div className="has-text-centered floating-text">
                  <span className="clean-text">Work Fast</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <div id="features" className="section section-feature-grey is-medium is-skewed-sm">
        <div className="container is-reverse-skewed-sm">
          <div className="section-title-wrapper has-text-centered">
            {/*TODO: <div className="bg-number">3</div>*/}
            <h2 className="section-title-landing">Features Of <b className={'is-primary'}>Grafix</b></h2>
          </div>

          <div className="content-wrapper hover-cards">
            <div className="columns is-vcentered">
              <div className="column is-10 is-offset-1">
                <div className="columns is-vcentered">
                  <div className="column is-6">
                    <div className="flex-card icon-card-hover fourth-card light-bordered">
                      <h3 className="card-title is-clean">High Quality</h3>
                      <p className="card-description">
                        Best And High Quality Of Graphic Designs & Motions Graphics
                      </p>
                    </div>
                    <div className="flex-card icon-card-hover second-card light-bordered">
                      <h3 className="card-title is-clean">Flexibility</h3>
                      <p className="card-description">
                        High Flexibility In Communication And Response And Satisfy The Customer's Desires.
                      </p>
                    </div>
                    <div className="flex-card icon-card-hover fifth-card light-bordered">
                      <h3 className="card-title is-clean">Push Notifications</h3>
                      <p className="card-description">
                        Push Notifications To The Client Individually, According To His Needs
                      </p>
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="flex-card icon-card-hover third-card light-bordered">
                      <h3 className="card-title is-clean">Filter Options</h3>
                      <p className="card-description">
                        Search Options Make It Easy For You To Find Old Tasks And Resend It
                      </p>
                    </div>
                    <div className="flex-card icon-card-hover first-card light-bordered">
                      <h3 className="card-title is-clean">Quick Updates</h3>
                      <p className="card-description">
                        We Are Constantly Updating The Software To Satisfy Customers, Continuously And Quickly
                      </p>
                    </div>
                    <div className="flex-card icon-card-hover sixth-card light-bordered">
                      <h3 className="card-title is-clean">24 X 7 Support</h3>
                      <p className="card-description">
                        Passionate About Building Strong Customer Relationships, Driving Brand Loyalty
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-wrap has-text-centered is-title-reveal" data-sr-id="1"
                 style={{visibility: 'visible',  WebkitTransform: 'translateY(0) scale(1)', opacity: 1,
                   transform: 'translateY(0) scale(1)',
                   WebkitTransition: '-webkit-transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s',
                   transition: 'transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s'}}>
              <ScrollLink to="sign-up-form" offset={-200} smooth={true} className="button button-cta btn-align raised primary-btn raised">
                Request Your Account Now!
              </ScrollLink>
            </div>
          </div>
        </div>
      </div>

      {/* TODO: Side nav-menu */}
      {/*<div className="side-navigation-menu">*/}
      {/*  <div className="category-menu-wrapper">*/}
      {/*    <ul className="categories">*/}
      {/*      <li className="square-logo"><img src={squareWhite} alt=""/></li>*/}
      {/*      <li className="category-link is-active" data-navigation-menu="demo-pages"><i*/}
      {/*        className="sl sl-icon-layers"/></li>*/}
      {/*      <li className="category-link" data-navigation-menu="onepagers"><i className="sl sl-icon-docs"/></li>*/}
      {/*      <li className="category-link" data-navigation-menu="components"><i className="sl sl-icon-grid"/></li>*/}
      {/*    </ul>*/}

      {/*    <ul className="author">*/}
      {/*      <li>*/}
      {/*        <a href={"#"} target="_blank">*/}
      {/*          <img className="main-menu-author" src={cssNinja} alt="" />*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}

      {/*  <div id="demo-pages" className="navigation-menu-wrapper animated preFadeInRight fadeInRight">*/}
      {/*    <div className="navigation-menu-header">*/}
      {/*      <span>Demo pages</span>*/}
      {/*      <a className="ml-auto hamburger-btn navigation-close" href={"#"} style={{opacity: 0}}>*/}
      {/*              <span className="menu-toggle">*/}
      {/*                  <span className="icon-box-toggle">*/}
      {/*                      <span className="rotate">*/}
      {/*                          <i className="icon-line-top"/>*/}
      {/*                          <i className="icon-line-center"/>*/}
      {/*                          <i className="icon-line-bottom"/>*/}
      {/*                      </span>*/}
      {/*              </span>*/}
      {/*              </span>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <ul className="navigation-menu">*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">weekend</span>Agency</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Homepage</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>About</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Portfolio</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Contact</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Blog</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Post no sidebar</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">wb_incandescent</span>Startup</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Homepage</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>About</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Product</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Contact</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Sign up</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span className="material-icons">apps</span>Software</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Conference v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Conference v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Projects v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Healthcare v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Healthcare v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v6</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v7</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v8</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v9</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business 10</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Features v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Features v2</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span className="material-icons">timer</span>Freelancer</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v6</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v7</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v8</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v9</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v10</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Freelancer v11</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Features v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Features v2</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">voice_chat</span>Videoconference</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Videoconference v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Videoconference v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Videoconference v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Chat v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Chat v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Features v1</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">domain</span>Business</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Crypto v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Crypto v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Crypto v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Crypto v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Business v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Banking v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Banking v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Banking v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Car Rental v1</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">shopping_cart</span>Ecommerce</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Shop page</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Landing page</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Landing page</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Product page</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Cart</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Payment</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v6</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v7</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>SaaS v8</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span className="material-icons">mouse</span>Services</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Consulting v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Consulting v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Consulting v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Consulting v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>HR v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>HR v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Collaboration v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Collaboration v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Collaboration v3</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span className="material-icons">work</span>Jobs</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Jobs v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Jobs v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Work v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Work v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Work v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Work v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Work v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Work v6</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Work v7</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Development v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Development v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Development v3</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children">*/}
      {/*        <a className="parent-link" href={"#"}>*/}
      {/*          <span className="material-icons">people</span>Customers</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v6</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v7</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v8</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>CRM v9</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Job List</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*  <div id="onepagers" className="navigation-menu-wrapper animated preFadeInRight fadeInRight is-hidden">*/}
      {/*    <div className="navigation-menu-header">*/}
      {/*      <span>Sub Pages</span>*/}
      {/*      <a className="ml-auto hamburger-btn navigation-close" href={"#"} style={{opacity: 0}}>*/}
      {/*              <span className="menu-toggle">*/}
      {/*                  <span className="icon-box-toggle">*/}
      {/*                      <span className="rotate">*/}
      {/*                          <i className="icon-line-top"/>*/}
      {/*                          <i className="icon-line-center"/>*/}
      {/*                          <i className="icon-line-bottom"/>*/}
      {/*                      </span>*/}
      {/*              </span>*/}
      {/*              </span>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <ul className="navigation-menu">*/}
      {/*      <li className="has-children"><a className="parent-link has-new" href={"#"}><span*/}
      {/*        className="material-icons">account_circle</span>Personal</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Profile v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Profile v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Profile v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Profile v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Profile v5</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link has-new" href={"#"}><span*/}
      {/*        className="material-icons">shopping_cart</span>Commerce</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Shop Home</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Product landing v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Product landing v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Product page</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Cart page</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Checkout page</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link has-new" href={"#"}><span*/}
      {/*        className="material-icons">domain</span>Company</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>About v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>About v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Case Study v1</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link has-new" href={"#"}><span*/}
      {/*        className="material-icons">call</span>Contact</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Contact v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Contact v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Contact v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Contact v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Contact v5</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link has-new" href={"#"}><span*/}
      {/*        className="material-icons">book</span>Blog</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts List v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts List v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts List v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts List v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts Grid v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts Grid v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts Grid v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts Grid v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Single Post V1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Single Post V2</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children">*/}
      {/*        <a className="parent-link has-new" href={"#"}><span className="material-icons">verified_user</span>Authentication</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v6</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v7</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v8</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Login v9</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Signup v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Signup v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Signup v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Signup v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Signup v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Signup v6</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children">*/}
      {/*        <a className="parent-link has-new" href={"#"}><span className="material-icons">highlight</span>Error Pages</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v1</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v2</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v3</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v4</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v5</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v6</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v7</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Error v8</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*  <div id="components" className="navigation-menu-wrapper animated preFadeInRight fadeInRight is-hidden">*/}
      {/*    <div className="navigation-menu-header">*/}
      {/*      <span>Components</span>*/}
      {/*      <a className="ml-auto hamburger-btn navigation-close" href={"#"} style={{opacity: 0}}>*/}
      {/*              <span className="menu-toggle">*/}
      {/*                  <span className="icon-box-toggle">*/}
      {/*                      <span className="rotate">*/}
      {/*                          <i className="icon-line-top"/>*/}
      {/*                          <i className="icon-line-center"/>*/}
      {/*                          <i className="icon-line-bottom"/>*/}
      {/*                      </span>*/}
      {/*              </span>*/}
      {/*              </span>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <ul className="navigation-menu">*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">view_quilt</span>Layout</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Grid system</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Parallax</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Headers</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Footers</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Typography</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Colors</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">subject</span>Navbars</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Fade light</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Fade dark</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Static*/}
      {/*            light</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Static*/}
      {/*            dark</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Static*/}
      {/*            solid</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Double*/}
      {/*            dark</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Double*/}
      {/*            light</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link has-new" href={"#"}><span*/}
      {/*        className="material-icons">view_stream</span>Sections</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Features</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Pricing</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Team</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Testimonials</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Clients</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>FAQ</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Counters</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Carousel</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Call To Action</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Posts</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Video</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">playlist_add_check</span>Basic*/}
      {/*        UI</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Cards</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Buttons</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Dropdowns</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Lists</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Modals</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Tabs &amp; pills</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Accordions</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Badges &amp; labels</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Popups</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">playlist_add</span>Advanced*/}
      {/*        UI</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Tables</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Timeline</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Boxes</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Messages</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Megamenu</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Calendar</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link has-new" href={"#"}><span*/}
      {/*        className="material-icons">check_box</span>Forms</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Inputs</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Controls</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Form layouts</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Step forms</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Uploader</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*      <li className="has-children"><a className="parent-link" href={"#"}><span*/}
      {/*        className="material-icons">adjust</span>Icons</a>*/}
      {/*        <ul>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Icons Mind</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Simple Line Icons</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Font Awesome</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"} target="_blank">Material Icons</a></li>*/}
      {/*          <li><a className="is-submenu" href={"#"}>Icon Picker</a></li>*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* Back-to-top button */}
      <div id="backtotop" className=""><a href={"#"}/></div>
    </>
  )
}

function SignupForm() {

  const firestore = useFirestore();
  const { register, handleSubmit, reset } = useForm();

  // Methods
  async function onSubmit(data) {
    try {
      const signUpRequest = new SignUpRequest(data)
      await signUpRequest.validate()
      await create(collection(firestore, "signup_requests"), signUpRequest.data)
      await reset()
    } catch (err) {
      await swal('Warning', err.message, 'warning')
    }
  }

  return(

    <form className="hero-form" onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
      <div className="flex-card">
        <div className="field">
          <label>Full Name <small>*</small></label>
          <div className="control has-icons-left">
            <input className="input is-medium has-shadow" type="text" placeholder="" autoComplete="chrome-off"
                   {...register('displayName')} />
            <span className="icon is-small is-left">
              <i className="sl sl-icon-user"/>
            </span>
          </div>
        </div>

        <div className="field">
          <label>Email Address <small>*</small></label>
          <div className="control has-icons-left">
            <input className="input is-medium has-shadow" type="text" placeholder="" autoComplete="chrome-off"
                   {...register('email')} />
            <span className="icon is-small is-left">
              <i className="sl sl-icon-envelope-open"/>
            </span>
          </div>
        </div>

        <div className="field">
          <label>Password <small>*</small></label>
          <div className="control has-icons-left">
            <input className="input is-medium has-shadow" type="password" placeholder=""
                   autoComplete="chrome-off" {...register('password')}/>
            <span className="icon is-small is-left">
              <i className="sl sl-icon-lock"/>
            </span>
          </div>
        </div>

        <div className="field">
          <label>Repeat Password <small>*</small></label>
          <div className="control has-icons-left">
            <input className="input is-medium has-shadow" type="password" placeholder=""
                   autoComplete="chrome-off" {...register('passwordConfirmation')}/>
            <span className="icon is-small is-left">
              <i className="sl sl-icon-lock"/>
            </span>
          </div>
        </div>

        <div className="field switch-toggle-wrap is-grouped pt-10 pb-10">
          <div className="control">
            <label className="slide-toggle">
              <input type="checkbox" defaultChecked={false} {...register('termsAgreed')}
                     autoComplete="chrome-off" />
              <span className="toggler">
                <span className="active">
                  <FeatherIcon icon={'check'} />
                </span>
                <span className="inactive">
                  <FeatherIcon icon={'circle'} />
                </span>
              </span>
            </label>
          </div>
          <div className="control">
            <span>By signing up, I agree to the Grafix Shop <Link to={'policy'}>Terms and Conditions</Link>.</span>
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button className="button button-cta primary-btn is-rounded is-bold is-fullwidth raised" type="submit">
              Request Account
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
