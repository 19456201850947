import {useState} from 'react';
import CookiesPolicy from 'views/polciesViews/CookiesPolicy';
import PrivacyPolicy from 'views/polciesViews/PrivacyPolicy';
import TermsOfServices from 'views/polciesViews/TermsOfServices';

export default function Policy() {

  const [activeTab, setActiveTab] = useState('Privacy Policy')

  const content = {
    "Privacy Policy": <PrivacyPolicy />,
    "Cookies Policy": <CookiesPolicy />,
    "Terms of Services": <TermsOfServices />
  }

  return(
    <div className="section">
      <div className="container">
        <div className="columns job-results-wrapper is-justify-content-between">
          <div className="column is-3 is-hidden-mobile">
            <div className="flex-card light-bordered raised">
              <div className="card-body">
                <div className="content">
                  <h2 className="no-margin title-bold">Policies</h2>
                </div>
                <ul className="list-block minimal" style={{padding: '0'}}>
                  <li className={`${activeTab === 'Privacy Policy' && 'is-active'}`}
                      onClick={() => setActiveTab('Privacy Policy')}>
                    <a>Privacy Policy</a>
                  </li>
                  <li className={`${activeTab === 'Cookies Policy' && 'is-active'}`}
                      onClick={() => setActiveTab('Cookies Policy')}>
                    <a>Cookies Policy</a>
                  </li>
                  <li className={`${activeTab === 'Terms of Services' && 'is-active'}`}
                      onClick={() => setActiveTab('Terms of Services')}>
                    <a>Terms of Services</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="column is-8">
            <h3 className="detailed-feature-subtitle">{activeTab}</h3>
            <div className="title-divider"/>
            <div className="section-feature-description policy-container">
              {content[activeTab]}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
