import Home from 'views/Home';
import Pricing from 'views/Pricing';
import Policy from 'views/Policy';

const routes = [
  // ========================================= Public routes =========================================
  {
    path: '/',
    name: 'Home Page',
    icon: 'home',
    component: Home
  },
  {
    path: '/pricing',
    name: 'Pricing',
    icon: 'prices',
    component: Pricing
  },
  {
    path: '/policy',
    name: 'Policy',
    icon: 'policy',
    component: Policy
  }
];
export default routes;
