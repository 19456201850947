/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

"use strict";

const env = "customization";

// TODO
// import {initPageLoader} from "./components/pageloader";
// import $ from "jquery";
import lozad from 'lozad';
import feather from "feather-icons"
import * as NavBar from "./components/navbar";
import * as Dropdown from "./components/dropdowns";
import * as Sidebar from "./components/sidebar";
import * as ThemeSwitcher from "./components/themeswitcher";
import * as Slider from "./components/slider";
import * as Utilities from "./utilities/utilities";
import * as Tabs from "./components/tabs";
import * as Cards from "./components/cards";
import * as Tilt from "./components/tilt";
import * as Popups from "./components/popups";
import * as Modal from "./components/modal";
import * as Counters from "./components/counters";
import * as Accordion from "./components/accordion";
import * as Toast from "./components/toast";
import * as Countdown from "./components/countdown";
import * as Carousel from "./components/carousel";
import * as Datetime from "./form/datetime";
import * as Select from "./form/select";
import * as Autocomplete from "./form/autocomplete";
import * as Input from "./form/input";
import * as Bulma from "./form/bulma";
import * as Combo from "./form/combo";
// import * as Uploader from "./components/uploader";
import * as Video from "./components/video";
import * as Pricing from "./components/pricing";
import * as Demo from "./utilities/demo";
import * as Backtotop from "./components/backtotop";
import * as QuickView from "./components/quickview";
import * as Marquee from "./components/marquee";
import * as Mockup from "./components/mockup";
import * as Search from "./components/search";
import * as Faq from "./components/faq";
import * as Auth from "./features/auth";
import * as Animation from "./components/animations";
import * as Map from "./components/map";


export function initLayout() {
	//1. Preload page
	// initPageLoader()

	//2. Lazy loading
	const el = document.querySelectorAll('[data-lazy-load]');
	const observer = lozad(el, {
		loaded: function(el) {
			// Custom implementation on a loaded element
			el.parentNode.classList.add('loaded');
		}
	});

	observer.observe();

	//4. Init Feather icons
	feather.replace();

	//5. Init Layout
	NavBar.initNavbar();
	NavBar.initLandingNavbar();
	NavBar.initMobileMenu();
	NavBar.initLandingMobileMenu();
	NavBar.initEcommerceNavbar();
	Dropdown.initNavbarDropdown();
	Sidebar.initSidebar();
	ThemeSwitcher.initThemeSwitcher();
	Utilities.initBackgroundImages();

	//6. Components
	Slider.initSlider();
	Dropdown.initDropdowns();
	Tabs.initTabsNav();
	Tabs.initNavigationTabs();
	Tabs.initVerticalTabs();
	Cards.initMediaCards();
	Tilt.initTiltCards();
	Popups.initPopovers();
	Popups.initTooltips();
	Modal.initModals();
	Counters.initCounters();
	Accordion.initSimpleAccordion();
	Accordion.initAccordions();
	Toast.initToasts();
	Countdown.initCountdown();

	//7. Carousels
	Carousel.initBasicCarousel();
	Carousel.initVerticalCarousel();
	Carousel.initFlatCarousel();
	Carousel.initImageCarousel();
	Carousel.initSingleImageCarousel();
	Carousel.initMultipleImagesCarousel();

	//8. Forms
	Datetime.initDatepicker();
	Datetime.initTimepicker();
	// Datetime.initDatepickerAlt();
	Select.initChosenSelects();
	Select.initMaterialSelect();
	Autocomplete.initAutocompletes();
	Input.initFileInputs();
	Input.initRangeInput();
	Input.initRangeInputs();
	Input.initJqueryTagInput();
	Bulma.initBulmaTags();
	Bulma.initBulmaSteps();
	Bulma.initBulmaIconpicker();
	Bulma.initBulmaCalendar();
	Combo.initComboBox();
	Combo.initImageComboBox();
	Combo.initStackedComboBox();
	// Uploader.initFileUploader(); TODO

	//9. Video
	Video.initVideoEmbed();
	Video.initBackgroundVideo();
	Video.initPlayers();

	//10. Demo
	Demo.initDemo();
	Utilities.initScrollspyNav();
	Utilities.initParallax();
	Backtotop.initBackToTop();

	//11. Utility functions
	Utilities.initGitem();
	Utilities.initAnchorScroll();
	QuickView.initQuickview();
	Utilities.initScrollReveal();
	Marquee.initMarquee();

	//12. Page specific methods
	Mockup.initMockup();
	Carousel.initClientsCarousel();
	Carousel.initPeopleCarousel();
	Carousel.initCustomCarousel();
	Carousel.initCarousel();
	Carousel.initLandingCarousel();
	Carousel.initTestimonials();
	Carousel.initCharacterTestimonials();
	Pricing.initPricing();
	Carousel.initPricingCarousel();
	Pricing.initTabbedPricing();
	Pricing.initFreelancerPricing();
	Pricing.initSwitchPricing();
	Pricing.initBoxedPricing();
	Pricing.initOnePagePricing();
	//initBlog();
	Search.initSearchBox();
	Carousel.initNavigationDots();
	Faq.initFaq();
	Auth.initAuth();
	Animation.initAnimations();
	Animation.initCanvas();
	Animation.initParticles();
	Animation.initAnimatedSvg();
	Animation.initChatWidget();
	Input.initContactToggler();
	Map.initGoogleMap();
	Map.initMapBox();
	Tabs.initCodeTabs();
}

// $(document).ready(initLayout)
