import {NavLink} from "react-router-dom";
import React from "react";

export default function Pricing() {

  return(
    <div id='pricing' className="section">
      <div className="container">
        <div className="pricing-wrapper" style={{maxWidth: 'unset'}}>
          <div className="pricing-tabs">
            <a className="tab-item is-active" data-tab="solo">
              <img src={require("assets/img/graphics/icons/custom-pricing-icon-5-azur.svg").default}
                   data-extension=".svg" alt="" />
              <span>Personal</span>
            </a>
            <a className="tab-item" data-tab="team">
              <img src={require("assets/img/graphics/icons/custom-pricing-icon-6-azur.svg").default}
                   data-extension=".svg" alt=""/>
              <span>Small Business</span>
            </a>
            <a className="tab-item" data-tab="enterprise">
              <img src={require("assets/img/graphics/icons/custom-pricing-icon-2-azur.svg").default}
                   data-extension=".svg" alt="" />
              <span>Enterprise & Government</span>
            </a>
            {/* TODO:CustomPricing */}
            {/*<a className="tab-item" data-tab="custom">*/}
            {/*  <img src={require("assets/img/graphics/icons/custom-pricing-icon-1-azur.svg").default}*/}
            {/*       data-extension=".svg" alt="" />*/}
            {/*  <span>Custom</span>*/}
            {/*</a>*/}
            <div className="naver">
              <div className="naver-inner">
                <div className="naver-naver"/>
              </div>
            </div>
          </div>

          <PricingContainer
            id={'solo'} active={true} header={'Personal'}
            paragraph={"This price Package is suitable If you working from home, don’t have a Store, and you sell your products through known Social Media accounts und only locally in your town."}
            sub={{
              first: {
                title: "Basic",
                monthlyPrice: 120,
                annualPrice: 1250,
                options: [
                  <><b>10</b> Designs per month</>,
                  <><b>(1)</b> 2D Animation video <br/> (10-20 second)</>,
                  <><b>24/7</b> Priority Email Support</>
                ]
              },
              second: {
                title: "Standard",
                monthlyPrice: 299,
                annualPrice: 3250,
                options: [
                  <><b>15 </b> Designs per month</>,
                  <><b>5 </b> Highlights design per month</>,
                  <><b>(2)</b> 2D Animation video <br/> (10-20 second)</>,
                  <><b>24/7</b> Priority Email Support</>
                ]
              }
            }}
          />

          <PricingContainer
            id={'team'} header={'Small Business'}
            paragraph={"This packages are suitable if you own a small business, Small store or you Sell your products only Locally in your town as example: Restaurant, Small Hospitals, cosmetics companies."}
            sub={{
              first: {
                title: "Gold",
                monthlyPrice: 330,
                annualPrice: 3500,
                options: [
                  <><b>15</b> Designs per month</>,
                  <><b>5</b> Highlights designs per month</>,
                  <><b>5</b> Story designs per month</>,
                  <><b>(1)</b> 2D Animation video <br/> (10-20 second)</>,
                  <><b>24/7</b> Priority Email Support</>
                ]
              },
              second: {
                title: "Silver",
                monthlyPrice: 850,
                annualPrice: 9000,
                options: [
                  <><b>30</b> Designs per Month</>,
                  <><b>10</b> Highlights Designs per Month</>,
                  <><b>10</b> Story Designs per Month</>,
                  <><b>(2)</b> 2D Animation Videos <br/>(10-20 second)</>,
                  <><b>24/7</b> Priority Email Support</>
                ]
              }
            }}/>

          <PricingContainer
            id={'enterprise'} hasAnnualPackage={false} header={'Enterprise & Government'}
            paragraph={"This packages are designed specifically for any Government Representative or reseller or with any government agency Directly or any Enterprise with more than two store branches in more than one country."}
            sub={{
              first: {
                title: "A",
                monthlyPrice: 1990,
                annualPrice: 1990,
                options: [
                  <><b>30 Hours</b> Designs</>,
                  <><b>25 Hours</b> Video Editing</>,
                  <><b>10 Hours</b> Motion Graphics</>,
                  <><b>5 Hours</b> Color Correction for Videos</>,
                  <><b>1</b> Premium Music for Your Videos</>,
                  <><b>24/7</b> Priority Email Support</>
                ]
              },
              second: {
                title: "B",
                monthlyPrice: 2650,
                annualPrice: 2650,
                options: [
                  <><b>35 Hours</b> Designs</>,
                  <><b>30 Hours</b> Video Editing</>,
                  <><b>15 Hours</b> Motion Graphics</>,
                  <><b>10 Hours</b> Color Correction for Videos</>,
                  <><b>2</b> Premium Music for Your Videos</>,
                  <><b>24/7</b> Priority Email Support</>
                ]
              }
            }}/>

          <div id="custom" className="pricing-container">
            <div className="container-inner no-padding">
              <div className="plan-focus">
                <div className="left-side">
                  <div className="header">
                    <img src={require("assets/img/graphics/icons/custom-pricing-icon-1-core.svg").default}
                         data-extension=".svg" alt=""/>
                    <span>Custom</span>
                  </div>
                  <div className="plan-tagline">
                    <p>
                      Get everything from the Enterprise plan and ask for
                      personalized implementations and development
                    </p>
                  </div>
                  <div className="price">
                    <div className="control">
                      <input type="text" className="input is-rounded" placeholder="Your team size"/>
                    </div>
                    <div className="amount">99</div>
                  </div>
                  <div className="price-info">
                    <p>
                      Contact us to discuss planning of your business needs and your
                      project scope.
                    </p>
                  </div>
                  <div className="button-wrap">
                    <button className="button is-fullwidth primary-btn is-rounded">
                      Schedule Demo
                    </button>
                  </div>
                  <div className="benefits content">
                    <ul>
                      <li>Includes everything from <span>Enterprise</span></li>
                      <li>Custom <span>features and implementations</span></li>
                      <li>Personalized <span>software integrations</span></li>
                    </ul>
                  </div>
                </div>
                <div className="right-side">
                  <div className="feature-carousel">
                    <div className="carousel-item">
                      <div className="header">
                        <div className="header-inner">
                          <div className="title-wrap">
                            <h4>Awesome features</h4>
                            <h3>Segment customer groups easily</h3>
                          </div>
                        </div>
                        <div className="subheader">
                          <p>
                            You can also bla bla bla bla and o=lorem ipsum sit
                            dolor
                          </p>
                        </div>
                      </div>
                      <img src={require("assets/img/graphics/legacy/pricing-1.png").default}
                           className="carousel-image" alt=""/>
                    </div>
                    <div className="carousel-item">
                      <div className="header">
                        <div className="header-inner">
                          <div className="title-wrap">
                            <h4>Awesome features</h4>
                            <h3>Segment customer groups easily</h3>
                          </div>
                        </div>
                        <div className="subheader">
                          <p>
                            You can also bla bla bla bla and o=lorem ipsum sit
                            dolor
                          </p>
                        </div>
                      </div>
                      <img src={require("assets/img/graphics/legacy/pricing-2.png").default}
                           className="carousel-image" alt=""/>
                    </div>
                    <div className="carousel-item">
                      <div className="header">
                        <div className="header-inner">
                          <div className="title-wrap">
                            <h4>Awesome features</h4>
                            <h3>Segment customer groups easily</h3>
                          </div>
                        </div>
                        <div className="subheader">
                          <p>
                            You can also bla bla bla bla and o=lorem ipsum sit
                            dolor
                          </p>
                        </div>
                      </div>
                      <img src={require("assets/img/graphics/legacy/pricing-3.png").default}
                           className="carousel-image" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


function PricingContainer({id, active = false, hasAnnualPackage = true, paragraph, header,
                            sub = {first: {}, second: {}}}) {
  return(
    <div id={id} className={`pricing-container ${active && 'is-active'}`} style={{border: 'unset'}}>
      <div className="container-inner">
        <div className="columns is-vcentered is-justify-content-space-around">
          <div className="column is-4 has-text-left">
            <div className="period-select">
              {hasAnnualPackage && <span className={`clean-text is-active`}>Monthly</span>}
              {hasAnnualPackage && <span className="clean-text">Annually</span>}
            </div>
            <h2 className="title theme clean-text pb-10 pt-10 is-2 no-margin">
              {header}
            </h2>
            <br/>
            <div className="subtitle is-6 mt-20">
              {paragraph}
            </div>
            <br/>
          </div>

          <div className="column is-7">
            {/*Freelance pricing*/}
            <div id="freelance-pricing" className="side-pricing">
              <div className="columns is-vcentered">
                <div className="column is-6">
                  {/*Pricing table features*/}
                  <div className="pricing-card animated preFadeInLeft fadeInLeft">
                    <div className="popular"><i className="im im-icon-Crown-2"/></div>
                    <div className="inner">
                      <h6 className="plan-name">{sub.first.title}</h6>
                      <div className="plan-price">
                        <small className="currency">$</small>
                        <div className="month-price animated preFadeInUp fadeInUp">
                          <span>{sub.first.monthlyPrice}</span>
                          <small>/ month</small>
                        </div>
                        <div className="year-price animated preFadeInUp fadeInUp is-hidden" >
                          <span>{sub.first.annualPrice}</span>
                          <small>/{hasAnnualPackage? 'year' : 'month'}</small>
                        </div>
                      </div>
                      <ul>
                        {sub.first.options?.map( (option, index) => <li key={index}>{option}</li>)}
                      </ul>
                      <NavLink className="button button-cta primary-btn raised" exact
                               to={{pathname: '/', hash: '#sign-up-form'}} activeClassName={'is-active'}>
                        Sign Up
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  {/*Pricing table transparent*/}
                  <div className="pricing-card transparent animated preFadeInLeft fadeInLeft delay-1">
                    <div className="inner">
                      <h6 className="plan-name">{sub.second.title}</h6>
                      <div className="plan-price">
                        <small className="currency">$</small>
                        <div className="month-price animated preFadeInUp fadeInUp">
                          <span>{sub.second.monthlyPrice}</span>
                          <small>/ month</small>
                        </div>
                        <div className="year-price animated preFadeInUp fadeInUp is-hidden">
                          <span>{sub.second.annualPrice}</span>
                          <small>/{hasAnnualPackage ? 'year' : 'month'}</small>
                        </div>
                      </div>
                      <ul>
                        {sub.second.options?.map( (option, index) => <li key={index}>{option}</li>)}
                      </ul>
                      <NavLink className="button button-cta primary-btn btn-outlined is-bold" exact
                               to={{pathname: '/', hash: '#sign-up-form'}} activeClassName={'is-active'}>
                        Sign Up
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*/Freelance pricing*/}
          </div>
        </div>
      </div>
    </div>
  )
}
